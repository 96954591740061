/* eslint-disable import/no-unresolved */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
'use client';
import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { ButtonType, commonTexts } from 'onair_frontend-lib';
import { AnalyticsBrowser } from '@segment/analytics-next';

import Footer from './footer';
import Button from './Button';
import NewsletterSignup from './newsletter-signup/NewsletterSignup';
import Navbar from './navbar/Navbar';
import { MessageBanner } from './banner';

import { useAppSelector } from '../state/hooks';
import { getItemFromLocalStorage } from '../state/localStorage';

import type { FC, ReactNode } from 'react';
import type { FooterProps } from './footer';
import type { ContentfulEventCarousel } from 'onair_frontend-lib';

import { useGetEnvironmentVariable } from '@/hooks/useGetEnvironmentVariable';
import { useIsPreviewMode } from '@/utils/contentful/useIsPreviewMode';
import {
    getCanonicalLink,
    renderSchemaCarousel,
    renderSchemaOrganization,
} from '@/utils/seo';
import { OGP } from '@/types/page-context';
import { vwoSmartCodeScript } from '@/utils/vwo-script';
import { getCookie } from 'cookies-next';
import { usePathname } from 'next/navigation';
import Script from 'next/script';
import { OneTrustTestScript } from './OneTrustTestScript';
import { SegmentScript } from './SegmentScript';

interface ShellProps {
    readonly children: ReactNode;
    readonly isFooterTopLineDisabled?: FooterProps['disableTopLine'];
    readonly isNewsletterSignupVisible?: boolean;
    readonly banner?: ReactNode;
    readonly pathname?: string;
    readonly slug?: string;
    readonly ogp?: OGP;
    readonly schemaData?: {
        carousel?: ContentfulEventCarousel;
        prerendered?: string;
    };
}

const LATEST_PLATFORM_MESSAGE_DATE = 'latestPlatformMessageDate';

// Translate site on static build
const handlePreviewModeExit = () => {
    if (typeof window === 'undefined') return;
    window.location = '/api/exit-draft' as any;
};

const ShellClientSide: FC<ShellProps> = ({
    children,
    slug,
    isFooterTopLineDisabled,
    banner,
    isNewsletterSignupVisible,
    schemaData,
    ogp,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    // pathname,
}) => {
    const { title, description, imageUrl } = ogp ?? {};
    const onAirUrl = 'onair.events';

    const canonicalLink = getCanonicalLink(onAirUrl, slug ?? '');

    const isNotProductionBuild =
        process.env.CONTENTFUL_ENVIRONMENT !== 'master';
    const {
        SEGMENT_ANALYTICS_TRACK_WRITE_KEY,
        SEGMENT_CDN_PROXY_HOST,
        SEGMENT_API_PROXY_HOST,
        ENABLE_ONE_TRUST,
    } = useGetEnvironmentVariable([
        'SEGMENT_ANALYTICS_TRACK_WRITE_KEY',
        'SEGMENT_CDN_PROXY_HOST',
        'SEGMENT_API_PROXY_HOST',
        'ENABLE_ONE_TRUST',
    ]);

    const trackingPreferencesCookie = getCookie('tracking-preferences');
    const marketingAndAnalytics = trackingPreferencesCookie
        ? JSON.parse(trackingPreferencesCookie).custom?.marketingAndAnalytics
        : undefined;

    const pathname = usePathname();
    useEffect(() => {
        if (!window.location.search.includes('watch-trailer')) {
            window.scroll(0, 0);
        }
    }, [pathname]);

    useEffect(() => {
        if (
            !ENABLE_ONE_TRUST ||
            !SEGMENT_ANALYTICS_TRACK_WRITE_KEY ||
            !SEGMENT_CDN_PROXY_HOST ||
            !SEGMENT_API_PROXY_HOST
        )
            return;

        const analytics = AnalyticsBrowser.load(
            {
                writeKey: !marketingAndAnalytics
                    ? ''
                    : SEGMENT_ANALYTICS_TRACK_WRITE_KEY,
                cdnURL: `https://${SEGMENT_CDN_PROXY_HOST}`,
            },
            {
                integrations: {
                    'Segment.io': {
                        apiHost: SEGMENT_API_PROXY_HOST,
                    },
                },
            },
        );

        if (typeof window !== 'undefined') {
            window.analytics = analytics;
            if (!marketingAndAnalytics) {
                // //@ts-ignore
                // window.analytics.identify = () => null;
                // //@ts-ignore
                // window.analytics.page = () => null;
                // //@ts-ignore
                // window.analytics.track = () => null;
            }
        }
    }, [
        SEGMENT_ANALYTICS_TRACK_WRITE_KEY,
        SEGMENT_CDN_PROXY_HOST,
        SEGMENT_API_PROXY_HOST,
    ]);

    const [showErrorBanner, setShowErrorBanner] = useState(false);
    const [platformPreviousUpdatedAt] = useState(
        getItemFromLocalStorage(LATEST_PLATFORM_MESSAGE_DATE),
    );
    const { description: platformDescription, updatedAt: platformUpdatedAt } =
        useAppSelector((state) => state.platformMessages);

    useEffect(() => {
        const hideErrorBanner =
            !platformUpdatedAt ||
            !platformDescription ||
            platformPreviousUpdatedAt === platformUpdatedAt;

        setShowErrorBanner(!hideErrorBanner);
    }, [platformDescription, platformPreviousUpdatedAt, platformUpdatedAt]);

    const isPreviewMode = useIsPreviewMode();

    const loggedIn = useAppSelector(
        (state) => state.auth.loginStatus === 'LOGGED_IN',
    );
    const customerInfo = useAppSelector(
        (state) => state.auth.token?.customerInfo,
    );
    const itemsInCart = useAppSelector(
        (state) => state.cartSlice.numberOfItemsInCart,
    );

    const showNewsletterSignup = isNewsletterSignupVisible && !loggedIn;

    return (
        <div>
            {renderSchemaOrganization(slug ?? '')}
            {schemaData?.carousel &&
                renderSchemaCarousel(slug ?? '', schemaData.carousel)}
            {schemaData && (
                <Script type="application/ld+json" id="prerendered">
                    {schemaData.prerendered}
                </Script>
            )}
            <Script
                type="text/javascript"
                id="vwoCode"
                dangerouslySetInnerHTML={{ __html: vwoSmartCodeScript }}
            />
            <head>
                <link
                    rel="shortcut icon"
                    type="image/png"
                    href="/assets/images/favicons/apple-touch-icon.png"
                />
                <link
                    rel="apple-touch-icon"
                    sizes="180x180"
                    href="/assets/images/favicons/apple-touch-icon.png"
                />
                <link
                    rel="icon"
                    type="image/png"
                    sizes="32x32"
                    href="/assets/images/favicons/favicon-32x32.png"
                />
                <link
                    rel="icon"
                    type="image/png"
                    sizes="16x16"
                    href="/assets/images/favicons/favicon-16x16.png"
                />
                <link
                    rel="manifest"
                    href="/assets/images/favicons/site.webmanifest"
                />
                <link
                    rel="mask-icon"
                    href="/assets/images/favicons/safari-pinned-tab.svg"
                    color="#fc4d3c"
                />
                {/* vwo smart code */}
                <link
                    rel="preconnect"
                    href="https://dev.visualwebsiteoptimizer.com"
                />
                <script
                    data-cfasync="false"
                    type="text/javascript"
                    id="vwoCode"
                >
                    {vwoSmartCodeScript}
                </script>
                <link rel="canonical" href={canonicalLink} />
                <meta name="msapplication-TileColor" content="#121112" />
                <meta name="theme-color" content="#121112" />
                <title>{title}</title>
                <meta property="og:title" content={title} />
                <meta
                    name="description"
                    property="og:description"
                    content={description}
                />

                {slug && (
                    <meta property="og:url" content={`${onAirUrl}/${slug}`} />
                )}
                <meta
                    property="og:site_name"
                    content={commonTexts.company_name}
                />
                {imageUrl && <meta property="og:image" content={imageUrl} />}

                {isNotProductionBuild && (
                    <meta name="robots" content="noindex" />
                )}
                {renderSchemaOrganization(slug ?? '')}
                {schemaData?.carousel &&
                    renderSchemaCarousel(slug ?? '', schemaData.carousel)}
                {schemaData && (
                    <script type="application/ld+json">
                        {schemaData.prerendered}
                    </script>
                )}
                {ENABLE_ONE_TRUST === 'true' ? (
                    <OneTrustTestScript />
                ) : (
                    <SegmentScript />
                )}
            </head>
            <div className="shell-wrapper">
                <MessageBanner
                    type="ErrorMessage"
                    hideBanner={() => setShowErrorBanner(false)}
                />

                <Button
                    className="skip-main"
                    type={ButtonType.SOLID_PRIMARY}
                    href="#main"
                >
                    Skip to Content
                </Button>

                {banner}

                <Navbar
                    loggedIn={loggedIn}
                    firstName={customerInfo?.firstName}
                    lastName={customerInfo?.lastName}
                    transparentBackground
                    itemsInCart={itemsInCart}
                    isBannerDisplayed={!!banner || showErrorBanner}
                />
                <main
                    id="main"
                    className={classNames('main', {
                        'main--with-banner': !!banner || showErrorBanner,
                    })}
                >
                    {children}
                    {showNewsletterSignup && <NewsletterSignup />}
                    {isPreviewMode && (
                        <div
                            onClick={handlePreviewModeExit}
                            className="exit-preview"
                        >
                            Exit preview mode
                        </div>
                    )}
                    <Footer
                        disableTopLine={
                            isFooterTopLineDisabled && !showNewsletterSignup
                        }
                    />
                </main>
            </div>
        </div>
    );
};

export default ShellClientSide;
