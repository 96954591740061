/* eslint-disable @next/next/no-sync-scripts */
/* eslint-disable unicorn/text-encoding-identifier-case */
import { segmentScript } from '@/utils/segment-script';

export const SegmentScript = () => {
    return (
        <>
            {/* <!-- Segment Analytics script --> */}
            <script>{segmentScript()}</script>
        </>
    );
};
