/* eslint-disable @next/next/no-sync-scripts */
/* eslint-disable unicorn/text-encoding-identifier-case */
import { oneTrustScript } from '@/utils/onetrust-script';

export const OneTrustTestScript = () => {
    return (
        <>
            <script
                src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"
                type="text/javascript"
                charSet="UTF-8"
                data-domain-script="01909377-eaf0-7220-b894-0c4df12f75ee-test"
            ></script>
            <script type="text/javascript">{oneTrustScript()}</script>
        </>
    );
};
